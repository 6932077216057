.series {
  position: relative;

  padding: 20px;

  height: 100%;

  overflow-y: auto;

  display: grid;
  grid-template-rows: auto 1fr;
  gap: 20px;
}
