.modal-section-list {
  display: grid;
  gap: 10px;
  grid-auto-rows: 48px;

  overflow-y: auto;
  height: 70vh;

  .modal-section {
    display: grid;
    &-container {
      display: grid;
      grid-template-columns: 1fr auto;
      cursor: pointer;

      border-bottom: 1px solid black;

      &--selected,
      &--selected:hover {
        background: rgb(229, 250, 248);
      }

      &:hover {
        background: rgb(252, 246, 246);
      }
    }

    &__title,
    &__description {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__index {
      color: rgb(196, 36, 36);
    }
  }
}
