.loading-spinner {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	border: 10px solid #dbdbdbff;
	border-right-color: transparent;
	display: inline-block;
	animation: 0.75s linear 0s infinite normal both running spinner;
	background: transparent !important;

	&-container {
		position: absolute;
		left: 0;
		top: 0;

		z-index: 100;

		display: flex;
		justify-content: center;
		align-items: center;

		height: 100%;
		width: 100%;

		background: rgba(255, 255, 255, 0.5);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg) scale(1);
	}
	50% {
		transform: rotate(180deg) scale(0.8);
	}
	100% {
		transform: rotate(360deg) scale(1);
	}
}