.dropDown {
  position: absolute;

  padding: 12px 8px 16px;

  width: 179px;

  background: var(--color--dropdown-bg);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 0 1px rgba(67, 90, 111, 0.47);
  border-radius: 8px;

  user-select: none;

  z-index: 2;

  &Option {
    margin: 2px 0;
    padding: 8px;

    background: transparent;

    display: grid;
    grid-template-columns: 1fr 15px;
    align-items: center;
    gap: 10px;

    transition: all 0.35s;

    font-weight: 600;
    font-size: 13px;
    line-height: 22px;

    border-radius: 8px;

    cursor: pointer;

    &:hover {
      background: var(--color--light-grey);
    }
  }
}

.header {
  top: 39px;
  right: -5px;
}

.banner {
  top: 39px;
  right: -5px;
}

.delete {
  &:hover {
    background: var(--color--red);
    color: var(--color--white);
  }
}

.localization {
  top: 47px;
  right: 11px;
}
