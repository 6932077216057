.localizationContainer {
  position: relative;

  display: grid;
  grid-template-columns: 1fr auto;
  background-color: white;

  .accordionItem {
    border-right: none;
  }
}

.buttonContainer {
  padding: 10px 10px 0 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-left: none;
}
