.bannersContainer {
  height: 100%;
  overflow-y: auto;

  display: grid;
  grid-template-rows: auto 1fr;
}

.bannersControls {
  display: inline-grid;
  gap: 20px;
  grid-template-columns: repeat(2, auto);
  &Container {
    padding: 10px 20px;
    width: 100%;
  }
}
