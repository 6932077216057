.banner {
  position: relative;

  margin: 0 auto;

  width: 90%;

  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: #c4bfbf08;

  display: grid;
  gap: 16px;

  grid-template-columns: auto 1fr;
  &Cover {
    min-height: 166px;
    width: 354px;

    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }

  &Info {
    gap: 10px;
    grid-auto-rows: auto;
  }

  &Type {
    padding-right: 50px;
  }

  &Advertisement {
    &Link,
    &Title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.book {
  &Title {
    padding-right: 30px;

    max-height: 48px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &Cover {
    width: 100%;
    max-width: 63px;

    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
}

.buttonContainer {
  position: absolute;
  top: 5px;
  right: 10px;
}
