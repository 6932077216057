.series-list {
  margin-top: 20px;
  overflow-y: auto;

  border-top: 1px solid rgba(0, 0, 0, 0.125);

  &::-webkit-scrollbar {
    width: 17px;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    margin: 8px 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
    background: #D9D8DE;
    border-left: 3px solid transparent;
    border-right: 7px solid transparent;
    background-clip: content-box;
  }
}
