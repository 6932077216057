.book-recommendation{
  padding: 5px;
  max-width: 400px;
  width: 400px;

  display: grid;
  grid-template-columns: 76px 1fr;

  background: #e9ecef;

  border: 1px solid black;
  border-radius: 5px;

  cursor: pointer;

  &__cover{
    width: 100%;
  }

  &__title{
    padding: 5px;
    font-size: 1.3em;
    font-weight: 500;
  }
}