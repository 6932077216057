.series-book-list {
  .book {
    width: 100%;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: #c4bfbf08;

    display: grid;
    grid-template-columns: 76px 1fr 100px;

    transition: all 0.35s;

    &__image {
      width: 76px;
    }

    &-info {
      padding: 10px;
    }
  }
}
