.container {
    padding: 20px;
    min-height: 200px;
    display: flex;
    justify-content: center;


    .image {
        height: 300px;
        width: 200px;
        &_container {
            position: relative;
            height: 300px;
            width: 200px;

            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .edit {
        position: absolute;
        width: 40px;
        height: 40px;
        z-index: 9;
        fill: #761FE0;
    }

    .editButton {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;

        cursor: pointer;

        :hover {
            opacity: 0.01;
        }
    }

    .input {
        display: none;
    }
}