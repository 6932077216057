.section-banner-list {
  width: 100%;

  display: grid;
  gap: 10px;

  p {
    margin-bottom: 0;
  }
}
