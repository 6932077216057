.creator{
  padding: 10px;

  display: grid;
  grid-template-columns: 76px 1fr;
  align-items: center;
  gap: 10px;

  background: #e9ecef;

  border: 1px solid black;
  border-radius: 5px;

  cursor: pointer;

  transition: background .35s;

  &:hover{
    background: #bcc4c9;
  }

  &__photo{
    width: 100%;

    border-radius: 50%;
  }

  &-info{
    display: grid;
    gap: 5px;
  }

  &__name{
    font-weight: 500;
  }

  &__email{
    font-weight: 500;
  }

  &__role{
    &--ADMIN{
      font-weight: 700;
      color: red;
    }
  }
}