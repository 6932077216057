.book-draggable{
  padding: 5px;

  display: grid;
  grid-template-columns:30px 1fr 30px;
  align-items: center;
  gap: 20px;
  width: 100%;
  background: #e9ecef;

  border: 1px solid black;
  border-radius: 5px;

  &__icon{
    width: 30px;
    height: 30px;

    &--cursor {
      cursor: pointer;
    }
  }

  &-content{
    display: grid;
    grid-template-columns: 76px 1fr;
  }

  &__cover{
    width: 100%;
  }

  &__title{
    padding: 5px;
    font-size: 1.3em;
    font-weight: 500;
  }
}