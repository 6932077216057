.modal-genre-list {
  display: grid;
  grid: 10px;
  grid-auto-rows: 40px;

  .modal-genre {
    cursor: pointer;

    border-bottom: 1px solid black;

    .genre-info {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 5px;
      padding: 10px;

      &__title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &__index {
        color: rgb(196, 36, 36);
      }
    }

    &:hover {
      background: rgb(252, 246, 246);
    }

    &--selected,
    &--selected:hover {
      background: rgb(229, 250, 248);
    }
  }
}
