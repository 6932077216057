.banners {
  &List {
    padding: 20px 20px 40px;

    height: 100%;
    width: 100%;

    overflow-y: auto;

    display: grid;
    gap: 20px;
    grid-auto-rows: auto;

    &::-webkit-scrollbar {
      width: 17px;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      margin: 8px 0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      border-top-right-radius: 9px;
      border-bottom-right-radius: 9px;
      background: #D9D8DE;
      border-left: 3px solid transparent;
      border-right: 7px solid transparent;
      background-clip: content-box;
    }
  }
}
