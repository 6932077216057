.field {
  padding: 10px;

  font-weight: 600;
  font-size: 14px;
  line-height: 110%;

  border-radius: 5px;
  background: var(--color--white);
  border: 1px solid #e4e4e4;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--color--white) inset !important;
    box-shadow: 0 0 0 30px var(--color--white) inset !important;
  }

  &Container {
    position: relative;

    padding-bottom: 20px;

    display: grid;
    gap: 5px;
  }

  &Error {
    color: var(--color--red);
    border: 2px solid var(--color--red);
    padding: 9px 10px;
  }
}

.label {
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;

  letter-spacing: -0.408px;
}

.error {
  position: absolute;
  bottom: -16px;
  left: 5px;

  font-weight: 600;
  font-size: 13px;
  line-height: 17px;

  color: var(--color--red);
}
