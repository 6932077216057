.button {
  padding: 7px 15px;

  background: var(--color--pink);
  border-radius: 30px;

  color: var(--color--white);
  font-weight: bold;
  font-size: 15px;
  line-height: 100%;

  user-select: none;
}

.white {
  color: var(--color--main-dark);
  background: var(--color--white);
  border: 1px solid #bdbdbd;
}
