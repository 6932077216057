.book-modal {
  &-header {
    width: 100%;
  }

  &-author {
    display: flex;
    align-items: center;

    &__info {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__edit-icon {
      min-width: 24px;

      cursor: pointer;
    }
  }
}
