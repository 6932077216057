.localizations {
  display: grid;
  grid-auto-rows: auto 1fr;

  height: 100%;
  overflow: hidden;

  &-controls {
    padding: 10px 20px;
  }

  &-list {
    padding: 0 30px 50px;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 17px;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      margin: 8px 0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      border-top-right-radius: 9px;
      border-bottom-right-radius: 9px;
      background: #D9D8DE;
      border-left: 3px solid transparent;
      border-right: 7px solid transparent;
      background-clip: content-box;
    }
  }
}

.accordion-body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .table {
    margin-bottom: 0px;
  }
}
