.select {
  position: relative;

  padding: 10px 20px;

  width: 100%;
  height: 115.75px;

  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background-clip: border-box;
  background-color: #c4bfbf08;

  cursor: pointer;

  &DropDown {
    position: absolute;
    top: 0;
    left: 0;

    padding: 10px 20px;

    width: 100%;
    max-height: 400px;

    overflow-y: hidden;
    background: white;

    display: grid;
    gap: 10px;
    grid-template-rows: auto 1fr;

    z-index: 3;

    border: 1px solid rgba(0, 0, 0, 0.125);
  }
}

.bannerBook {
  background-clip: border-box;

  margin-bottom: 0;
  background-color: #c4bfbf08;

  display: grid;
  grid-template-columns: 75px 1fr;
  gap: 20px;

  transition: all 0.35s;

  &Cover {
    width: 75px;
  }

  &Info {
    padding: 10px;
  }
}

.book {
  cursor: pointer;

  width: 100%;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: #c4bfbf08;

  display: grid;
  grid-template-columns: 75px 1fr;
  gap: 20px;

  transition: all 0.35s;

  &Cover {
    width: 75px;
  }

  &Info {
    padding: 10px;
  }

  &List {
    padding-bottom: 5px;

    overflow-y: auto;
    display: grid;
    grid-auto-rows: 115.75px;
    gap: 10px;
  }
}

.error {
  position: absolute;
  bottom: -40px;
  left: 5px;

  font-weight: 600;
  font-size: 13px;
  line-height: 17px;

  color: var(--color--red);
}
