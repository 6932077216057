.search-bar {
  font-family: 'Lato', sans-serif;
  color: #2f2f2f;
  font-size: 1rem;
  border: 1px solid #2f2f2f;
  background: #fff;
  padding: 0.2rem 1.6rem;
  border-radius: 0.7em;
  appearance: none;
  height: 'fit-content';

  &:focus {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }
}
