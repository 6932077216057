.modal-banner-list {
  max-height: calc(100vh - 67px - 71px - 6rem);

  display: grid;
  gap: 10px;

  overflow: scroll;

  .banner {
    cursor: pointer;

    width: 100%;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: #c4bfbf08;

    display: grid;

    transition: all 0.35s;

    &__image {
      width: 75px;
    }

    &-info {
      padding: 10px;

      &-container {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 5px;

        .banner-selected-index {
          color: rgb(196, 36, 36);
        }
      }
    }

    &:hover {
      background: #fffcfc;
    }

    &--selected {
      background: #d2f0fc;

      &:hover {
        background: #d2f0fc;
      }
    }
  }
}
