.continue-reading-section {
  padding: 1rem 1.25rem;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 1rem;

  p {
    margin-bottom: 0;
  }
}
