*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
body {
  width: 100%;
  height: 100%;
}

img {
  object-fit: contain;
  vertical-align: baseline;
}

#root {
  width: 100%;
  height: 100%;
}

.modal-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.app-container {
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100%;

  position: relative;

  .alert {
    z-index: 1056;
    position: absolute;
    top: 5px;
    left: 5px;
  }
}

.simplebar-scrollbar::before {
  background: #E2E2E6 !important;
  width: 6px;
}

.simplebar-content-wrapper {
  border-end-end-radius: 8px;
  border-end-start-radius: 8px;
  border-bottom: 1px solid transparent !important;

	&:focus-visible {
		outline: none;
	}
}

.simplebar-vertical {
  margin: 6px 6px;
}

.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}

#file-input {
	display: none;
}
